<template>
  <tr>
    <td class='four wide'>
      <enhanced-content :key='value.id' :short-identifier='shortIdentifier' :value='value'></enhanced-content>
    </td>
    <td class='twelve wide'>
      <two-column-tables :parentId='value.id' identifier='Indicator' short-identifier='Indicator' types='Text'></two-column-tables>
    </td>
  </tr>
</template>

<script>
  import EnhancedContent from './enhanced_content.vue'
  import TwoColumnTables from './two_column_tables.vue'

  export default {
    props: {
      value: Object,
      shortIdentifier: String
    },
    components: { EnhancedContent, TwoColumnTables }
  }
</script>
