import "../../fomantic_ui/semantic.less"
import "../../fomantic_ui/semantic.js"

import '../froala/spm_reference'

import '../trix'

import '../previews'

import axios from 'axios'

import '../controllers'

import Vue2Storage from 'vue2-storage'
import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'

import Store from '../store'

import documentEventsMixin from '../documentEventsMixin'

import AddButton from '../components/add_button.vue'

import Components from '../components'

Vue.use(Vue2Storage, { prefix: null })
Vue.use(TurbolinksAdapter)

Vue.component('add-button', AddButton)
Vue.config.ignoredElements = ['trix-toolbar']

$(document).on('turbolinks:load', function() {
  const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common['Accept'] = 'application/json'
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  const document_element = document.getElementById('document')

  if (document_element) {
    $('.loading.dimmer').dimmer('show')

    new Vue({
      el: document_element,
      name: 'Document',
      store: Store,
      mixins: [documentEventsMixin],
      created() {
        this.$store.dispatch('initialise', document_element.getAttribute('data-document-url'))
        .then(function() {
          $('.loading.dimmer').dimmer('hide')
          window.vueStatus = 'loaded'
          document.dispatchEvent(new CustomEvent('tour:start'))
        })
      },
      components: Components
    })
  }
})

$(document).on('click', '.message .close', function() {
	$(this).closest('.message').transition('fly down')
})

$(document).on('turbolinks:load', function() {
  $('.ui.radio.checkbox').checkbox()
  $('select.dropdown').dropdown()
  $('.ui.message').transition({
    animation: 'pulse',
    interval: 500
  })
})

$(document).on('click', '[data-hide-editing-features], [data-show-editing-features]', function(event) {
  event.preventDefault()

  $('[data-hide-editing-features], [data-show-editing-features]').toggle()
  $('.document').toggleClass('preview')
})

// Prevent a form from being submitted on pressing Enter
$(document).on('submit', '.ui.modal form', event => event.preventDefault())

$(document).on('ajaxComplete', function(_event, xhr) {
  const login_path = xhr.getResponseHeader('X-Authentication-Required')
  if (login_path) { Turbolinks.visit(login_path) }
})
