<template>
  <table class='ui celled striped table' data-title='Reflective Questions'>
    <thead>
      <tr>
        <th class='six wide'>Reflective Questions</th>
        <th class='ten wide'>Answers</th>
      </tr>
    </thead>
    <draggable v-model='values' @change='move' v-bind='draggableOptions' tag='tbody'>
      <reflective-question v-for='value in values' :value='value' :key='value.id' :short-identifier='shortIdentifier' class='value'></reflective-question>
      <tr slot='footer' :class='{ add: hasValues }'>
        <td>
          <add-button short-identifier='Question' :types='types' v-on:open-add-modal='open_add_modal'></add-button>
        </td>
        <td></td>
      </tr>
    </draggable>
  </table>
</template>

<script>
  import ValuesList from './mixins/values_list'
  import ReflectiveQuestion from './reflective_question.vue'

  export default {
    mixins: [ValuesList],
    components: { ReflectiveQuestion }
  }
</script>
