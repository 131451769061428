import Sortable from "sortablejs"

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    const url = this.data.get('url')

    Sortable.create(this.containerTarget, {
      handle: 'td.handle',
      onEnd({ item, newIndex }) {
        $.ajax({
          url: `${url}/${item.dataset.id}/move`,
          method: 'PATCH',
          data: {
            position: newIndex
          }
        })
      }
    })
  }
}
