import ConversationsAndReflections from './conversations_and_reflections/index.vue'
import EvaluativeReflections from './evaluative_reflections/index.vue'
import ReferencesList from './references_list.vue'
import Reviews from './reviews/index.vue'

export default {
  ConversationsAndReflections,
  EvaluativeReflections,
  ReferencesList,
  Reviews
}
