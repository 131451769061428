import { Controller } from "stimulus"

import tours from '../tours'

export default class extends Controller {
  static values = { startAutomatically: { type: Boolean, default: true }, name: String, viewed: Array }

  static get shouldLoad() {
    return document.documentElement.dataset.environment !== 'test'
  }

  initialize() {
    // Mark the tour as read when it has been completed
    hopscotch.registerHelper('viewTour', () => $.get(`/tours/${this.nameValue}`))
    hopscotch.initializeBubble()
  }

  connect() {
    $(document).on('turbolinks:click', this.endTour)
    if (this.startAutomaticallyValue) {
      if (document.readyState === 'complete') {
        this.startTour()
      } else {
        window.addEventListener('load', this.startTour.bind(this))
      }
    }
  }

  disconnect() {
    $(document).off('turbolinks:click', this.endTour)
  }

  startTour() {
    if (this.hasNameValue && !this.viewedValue.includes(this.nameValue)) {
      hopscotch.startTour(tours[this.nameValue], 0)
    }
  }

  endTour() {
    hopscotch.endTour(true, false)
  }
}
