<template>
  <div class='ui basic compact centered segment'>
    <i class='fitted inverted grey ellipsis horizontal handle icon'></i>
    <div class='ui top right pointing value dropdown'>
      <i class='fitted grey write icon' title='Actions'></i>
      <div class='menu'>
        <div @click='open_edit_modal' class='item'>
          <i class='edit icon'></i>
          Edit
        </div>
        <div @click='destroy' class='item'>
          <i class='delete icon'></i>
          Destroy
        </div>
        <div class='blue header' v-bind:title='value.updated_at'>
          <i class='clock icon'></i>
          Updated: {{ formatted_date(value.updated_at) }}
        </div>
      </div>
    </div>
    <h3 class='ui date header'>
      <component v-bind='value.component'></component>
    </h3>
  </div>
</template>

<script>
  import DateFormatter from '../../date_formatter'
  import EditValueCoordinator from '../../documents/values/edit_value_coordinator'
  import ValueDestroyer from '../../documents/values/value_destroyer'

  import DateValue from '../values/date.vue'

  export default {
    props: {
      value: Object,
      shortIdentifier: String
    },
    methods: {
      formatted_date(date) {
        return DateFormatter.format(new Date(date))
      },
      open_edit_modal() {
        new EditValueCoordinator(this.shortIdentifier, { value: this.value })
      },
      destroy() {
        new ValueDestroyer(this).destroyValue()
      }
    },
    mounted() {
      $(this.$el).find('.ui.dropdown').dropdown({ action: 'hide' })
    },
    components: {
      DateValue
    }
  }
</script>
