import shared from './shared'

export default {
  id: "administrators-tour",
  showPrevButton: true,
  onEnd: ["viewTour"],
  onClose: ["viewTour"],
  steps: [
    {
      title: "Welcome!",
      content: "You're an administrator in this system. Here's a few tips to get you started...",
      target: ".logo.text.item",
      placement: "bottom",
      xOffset: 20,
      fixedElement: true,
      onNext() {
        document.dispatchEvent(new CustomEvent('openMenu'))
      }
    }, {
      title: "Add Users",
      content: "Your first step should be to add other users to the system so they can log in and begin working on their own documents.",
      target: ".menu .item[title=\"Users\"]",
      placement: "bottom",
      xOffset: 20,
      fixedElement: true,
      onPrev() {
        document.dispatchEvent(new CustomEvent('closeMenu'))
      }
    }, {
      title: "Group Users",
      content: "You can <strong>optionally</strong> group users and set group administrators who can view other users' documents.",
      target: ".menu .item[title=\"Groups\"]",
      placement: "bottom",
      xOffset: 20,
      fixedElement: true
    },
    ...shared
  ]
};
