<template>
  <div id='document_viewer'>
    <div class='fr-document-view' ref='document'>
      <div>
        <div class='fr-view' v-html='content' ref='content'></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      viewer() {
        return this.$store.state.documentViewer
      },
      isVisible() {
        return this.viewer.isVisible
      },
      content() {
        return this.viewer.content
      },
      title() {
        return this.viewer.title
      },
      referenceId() {
        return this.viewer.referenceId
      }
    },
    watch: {
      isVisible(isVisible) {
        if (isVisible) {
          this.$nextTick(function() {
            const $content = $(this.$refs.content)
            const $document = $(this.$refs.document)
            const $mark = $content.find(`mark[data-id=${this.referenceId}]`)

            $content.find('mark').not($mark).hide()

            $.fancybox.open({
              src: '#document_viewer',
              type: 'inline',
              touch: false,
              caption: this.title,
              toolbar  : true,
              smallBtn : false,
              autoFocus: false,
              video: {
                autoStart: false
              },
              afterShow: () => {
                if (!this.referenceId) { return }

                const offset = $mark.offset().top - $document.offset().top
                $document.animate({ scrollTop: offset - 10 }, 500)

                $mark.transition('set looping').transition('tada', '2000ms')
              },
              afterClose: () => {
                this.$store.commit('documentViewer/hide')
              }
            })
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #document_viewer {
    display: none;
    padding: 0;
    width: 90%;
  }

  .fr-document-view {
    max-height: calc(100vh - 200px);
    overflow: auto;

    line-height: initial;
  }

  @media (min-width: 992px) {
    .fr-document-view {
      min-width: 21cm;
    }
    .fr-document-view > div {
      text-align: center;
      padding: 30px;
      min-width: 21cm;
      background: #EFEFEF;
    }
    .fr-document-view > div > div {
      text-align: left;
      background: #FFF;
      width: 21cm;
      margin: auto;
      min-height: 26cm !important;
      padding: 1cm 2cm;
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
      overflow: visible;
    }
  }

  @media (max-width: 993px) {
    .fr-document-view > div > div {
      padding: 1em;
      background: #FFF;
    }
  }
</style>
