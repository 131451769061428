<template>
  <div class='one column row'>
    <div class='column'>
      <draggable @change='move' class='ui stackable grid' v-bind='draggableOptions' v-model='values'>
        <meeting :index='index' :key='value.id' :short-identifier='shortIdentifier' :value='value' class='value' v-for='(value, index) in values'></meeting>
        <div class='sixteen wide column' slot='footer'>
          <add-button :short-identifier='shortIdentifier' :types='types' v-on:open-add-modal='open_add_modal'></add-button>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
  import ValuesList from '../mixins/values_list'
  import Meeting from './meeting.vue'

  export default {
    mixins: [ValuesList],
    components: { Meeting }
  }
</script>
