export default class ValueDestroyer {
  constructor(component) {
    this.component = component
    this.document_url = this.component.$store.state.documentUrl

    this.value = this.component.value
    this.short_identifier = this.component.shortIdentifier

    this.modal = $('.ui.destroy.modal')
  }

  destroyValue() {
    this.modal.find('.header').text(`Destroy ${this.short_identifier}`)
    this.modal.find('.content').html(`<p>Are you sure you want to destroy this ${this.short_identifier}?</p>`)
    return this.modal.modal({
      closable: false,
      context: '.modal.context',
      onApprove: () => {
        this.component.$store.dispatch('destroyValue', this.value)
        .then(() => {
          this.modal.modal('hide')
        })

        return false
      }
    }).modal('show')
  }
}
