import FroalaEditor from 'froala-editor/js/froala_editor.pkgd.min'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'font-awesome/css/font-awesome.css'
import 'froala-editor/css/froala_style.min.css'
import './spm_reference.scss'

import Store from '../store'

(function($){
  FroalaEditor()

  $.FroalaEditor.DefineIcon('spmReference', { NAME: 'star' })
  $.FroalaEditor.RegisterCommand('spmReference', {
    title: 'Add Reference',
    icon: 'spmReference',
    focus: true,
    undo: false,
    refreshAfterCallback: false,
    callback() {
      this.spmReference.add()
    }
  })

  $.extend($.FroalaEditor.POPUP_TEMPLATES, { 'spmReference.edit': '[_BUTTONS_]' })

  $.FroalaEditor.DefineIcon('editSpmReference', { NAME: 'edit' })
  $.FroalaEditor.RegisterCommand('editSpmReference', {
    title: 'Edit',
    icon: 'editSpmReference',
    focus: false,
    undo: false,
    refreshAfterCallback: false,
    callback() {
      this.spmReference.edit()
    }
  })

  $.FroalaEditor.DefineIcon('removeSpmReference', { NAME: 'trash' })
  $.FroalaEditor.RegisterCommand('removeSpmReference', {
    title: 'Remove',
    icon: 'removeSpmReference',
    focus: false,
    undo: true,
    refreshAfterCallback: false,
    callback() {
      this.spmReference.remove()
    }
  })

  $.FroalaEditor.PLUGINS.spmReference = function(editor) {
    let reference = null

    const _init = () => editor.events.$on(editor.$el, 'click touchend', 'mark.reference', _edit)

    const add = function() {
      _cleanup()
      _prompt()
    }

    const edit = () => _prompt()

    const remove = function() {
      reference.remove()
      _hideEditPopup()
      _cleanup()
    }

    const _prompt = function() {
      editor.selection.save()

      _hideEditPopup()

      const payload = {
        referenceId: reference ? reference.attr('data-id') : null,
        persistReference
      }

      if (reference && reference.attr('data-identifiers')) {
        payload.identifiers = JSON.parse(reference.attr('data-identifiers'))
      }

      if (reference && reference.attr('data-title')) {
        payload.title = reference.attr('data-title')
      }

      Store.commit('documentReferenceModal/show', payload)
    }

    const persistReference = function(identifiers, title) {
      editor.undo.saveStep()

      if (!reference) { _insertReferenceNode() }
      reference.attr('data-identifiers', JSON.stringify(identifiers))
      reference.attr('data-title', title)

      return Promise.resolve()
    }

    const _cleanup = () => reference = null

    const _insertReferenceNode = function() {
      editor.selection.restore()
      editor.selection.ranges(0).collapse(true)

      editor.html.insert('<mark contenteditable="false" id="fr-just" class="reference fr-draggable" draggable="true"></mark>')
      reference = editor.$el.find('mark.reference#fr-just').removeAttr('id')
    }

    const _initEditPopup = function() {
      editor.events.$on(editor.$wp, 'scroll.spmReference-edit', function() {
        if (reference && editor.popups.isVisible('spmReference.edit')) {
          editor.events.disableBlur()
          _showEditPopup()
        }
      })

      const buttons = editor.button.buildList(['editSpmReference', 'removeSpmReference'])
      editor.popups.create('spmReference.edit', { buttons: `<div class='fr-buttons'>${buttons}</div>` })
    }

    const _showEditPopup = function() {
      editor.popups.get('spmReference.edit') || _initEditPopup()

      if (!editor.popups.isVisible('spmReference.edit')) {
        editor.popups.refresh('spmReference.edit')
      }

      editor.popups.setContainer('spmReference.edit', editor.$sc)
      const left = reference.offset().left + (reference.outerWidth() / 2)
      const top = reference.offset().top + reference.outerHeight()
      editor.popups.show('spmReference.edit', left, top, reference.outerHeight())
    }

    const _hideEditPopup = () => editor.popups.hide('spmReference.edit')

    const _edit = function() {
      reference = $(this)
      _showEditPopup()
    }

    return {
      _init,
      add,
      edit,
      remove,
      persistReference
    }
  }
})(jQuery)
