<template>
  <div class='file browser field'>
    <div class='ui fluid action top attached input'>
      <input :value='fileName' disabled name='name' placeholder='Browse...' type='text'>
      <div @click='browse' class='ui right labeled blue icon button'>
        <i class='folder open icon'></i>
        Browse
      </div>
    </div>
    <div class='ui bottom attached progress'>
      <div class='bar'></div>
    </div>
    <input @change='assignFile' name='value[file]' ref='fileInput' type='file'>
  </div>
</template>

<script>
  export default {
    props: {
      existingName: String
    },
    data() {
      return { fileName: null }
    },
    created() {
      this.fileName = this.existingName
    },
    methods: {
      browse() {
        this.$refs.fileInput.click()
      },
      assignFile() {
        const file = this.$refs.fileInput.files[0]
        this.fileName = file.name
      }
    }
  }
</script>
