export default {
  key: 'OA1D2D4A3nA16B10C7D6F2D4C3I2G3B8aQZMe1NJGC1HTMVANU==',
  documentReady: true,
  toolbarContainer: '#froala_toolbar',
  scrollableContainer: '#froala_content .fr-wrapper',
  zIndex: 1000,
  imageAddNewLine: true,
  enter: $.FroalaEditor.ENTER_DIV,
  toolbarButtons: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'color', 'clearFormatting', '|', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', '|', 'formatOL', 'formatUL', 'indent', 'outdent', '-', 'paragraphFormat', '|', 'fontFamily', '|', 'fontSize', '|', 'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable', 'specialCharacters', 'quote', 'spmReference', 'help'],
  fontFamily: {
    "'Open Sans','Helvetica Neue',Arial,Helvetica,sans-serif": 'Open Sans',
    'Arial,Helvetica,sans-serif': 'Arial',
    'Georgia,serif': 'Georgia',
    'Impact,Charcoal,sans-serif': 'Impact',
    'Tahoma,Geneva,sans-serif': 'Tahoma',
    "'Times New Roman',Times,serif": 'Times New Roman',
    'Verdana,Geneva,sans-serif': 'Verdana'
  },
  htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'mark'],
  linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
  linkInsertButtons: ['linkBack'],
  fileUploadParam: 'attachment[file]',
  fileUploadParams: {
    source: 'froala'
  },
  fileMaxSize: 1024 * 1024 * 500,
  imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
  imageDefaultWidth: 0,
  imageUploadParam: 'attachment[file]',
  imageUploadParams: {
    source: 'froala'
  },
  imageMaxSize: 1024 * 1024 * 500,
  videoDefaultWidth: 0,
  videoUploadParam: 'attachment[file]',
  videoUploadParams: {
    source: 'froala'
  },
  videoMaxSize: 1024 * 1024 * 500
}
