export default {
  id: "teacher-document-tour",
  showPrevButton: true,
  onEnd: ["viewTour"],
  onClose: ["viewTour"],
  steps: [{
    title: "Evidence Portfolio: Teacher",
    content: "This is the portfolio that you will use to meet one of the requirements to renew your PTC (Practising Teacher Certificate) on a three yearly cycle.",
    target: "titlepage",
    placement: "bottom",
    xOffset: "center",
    arrowOffset: "center"
  }
  , {
    title: "Resource Links",
    content: "Use these links to gain further information from the Teaching Council website about appraisal.",
    target: "resource-links",
    placement: "top"
  }
  , {
    title: "Teaching Council Standards",
    content: "The first six sections in the portfolio reflect the six Standards approved by the Teaching Council for appraisal purposes and for renewing your PTC.",
    target: "[data-title=\"Education Council Standard 1\"]",
    placement: "top"
  }
  , {
    title: "Links to Other Standards",
    content: "In this portfolio these sections also have the relevant links to the Collective Agreement Professional Standards and Tātaiako.",
    target: "[data-title=\"Links\"]",
    placement: "bottom",
    xOffset: "center",
    arrowOffset: "center"
  }
  , {
    title: "Add a New Evidence",
    content: "For each of the indicators within each standard you can add evidence in various forms. Once you add a piece of evidence, you can edit it by clicking the <i class=\"fitted grey write icon\"></i> icon next to it.",
    target: "[data-title=\"Evidence\"]",
    placement: "top"
  }
  , {
    title: "Data Types",
    content: "Depending on where you are adding data to your document you may be able to add all or a subset of: <strong>Text</strong>, <strong>Image</strong>, <strong>Link</strong>, <strong>File</strong>, and <strong>Mixed</strong> types. Experiment with each of these to get a feel for how they work.",
    target: "[data-title=\"Evidence\"]",
    placement: "top"
  }
  , {
    title: "Colouring your Evidence",
    content: "Clicking the <i class=\"fitted grey write icon\"></i> icon and hovering the <strong>Colour</strong> menu will allow you to select a colour to highlight your evidence with. You can use this to indicate evidence that is relevant for a certain year for example.",
    target: "[data-title=\"Evidence\"]",
    placement: "top"
  }
  , {
    title: "Elaborations for Catholic Schools",
    content: "The Catholic elaborations on the Teaching Council’s Standards for the Teaching Profession provide a framework for personal and professional reflection and support the growth of the encounter with Christ. These elaborations will be a helpful addition to the discussions that take place around job descriptions and appraisals.",
    target: "[data-title=\"Elaborations for Catholic Schools\"]",
    placement: "top"
  }
  , {
    title: "Reflective Questions",
    content: "This section is where you reflect on your professional growth and development as a reflective practitioner. Identify areas for change that you want to review and build on. As a result of your reflections, develop new strategies which are shown in your evidence.",
    target: "[data-title=\"Reflective Questions\"]",
    placement: "top"
  }
  , {
    title: "Teaching as Inquiry Research Questions",
    content: "In developing your 'spiral of inquiry' question(s) remember to reference the relevant Standards to ensure that the evidence you are gathering relates to your Reflective Questions and initiates the inquiry research you carry out. The evidence gathered should provide answers to your Reflective Questions and inquiry outcome.",
    target: "[data-title=\"Teaching as Inquiry Research Questions\"]",
    placement: "top"
  }
  , {
    title: "Māori / Pasifika Goals, Performance Goals, and Professional Development Goals",
    content: "Record your indicators of practice, and evidence that reflect your school goals (as established through your Charter and Strategic Plan). These goals are confirmed with your appraiser at the beginning of each cycle.",
    target: "[data-title=\"Māori / Pasifika Goals\"]",
    placement: "top"
  }
  , {
    title: "Specific Responsibilities",
    content: "Record your areas of responsibility within your school and identify indicators and evidence outcomes to show your appraiser.",
    target: "[data-title=\"Specific Responsibilities\"]",
    placement: "top"
  }
  , {
    title: "Professional Learning and Development Records",
    content: "Record evidence of other areas of professional practice that are not specifically related to your professional development goals.",
    target: "[data-title=\"Professional Learning and Development Records\"]",
    placement: "top"
  }
  , {
    title: "Appraisal Interview Notes",
    content: "Record a variety of evidence curated by your appraiser to inform your practice and professional development.",
    target: "[data-title=\"Appraisal Interview Notes\"]",
    placement: "top"
  }
  , {
    title: "Reference Documents",
    content: "Upload specific documents that inform your appraisal such as: School Charter, Strategic Plan, Job Description, Annual Plan, Curriculum Learning Plan, Assessment Guide.",
    target: "[data-title=\"Reference Documents\"]",
    placement: "top"
  }
  , {
    title: "Thank You! :)",
    content: "Thanks for using School Performance Management!",
    target: "#titlepage h1.header",
    placement: "bottom",
    xOffset: "center",
    arrowOffset: "center"
  }
  ]
}
