import { easepick } from '@easepick/core'
import coreCss from '@easepick/core/dist/index.css?url'

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.picker = new easepick.create({
      element: this.element,
      firstDay: 0,
      css: [coreCss],
      format: 'D MMMM YYYY',
      date: this.element.value
    })
  }

  disconnect() {
    this.picker.destroy()
  }
}
