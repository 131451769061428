export default class ColourChooser {
  constructor(component) {
    this.component = component
    this.id = this.component.value.id
    this.document_url = this.component.$store.state.documentUrl
  }

  setColour(colour) {
    $.ajax({
      url: `${this.document_url}/values/${this.id}/set_colour`,
      method: 'PATCH',
      data: {
        colour
      }
    }).done(content => {
      this.component.$store.commit('updateValue', content)
    })
  }
}
