<template>
  <table class='ui bottom attached celled striped table'>
    <thead>
      <tr>
        <th class='nine wide'>Conversations and Reflections</th>
        <th class='seven wide'>Evaluative Reviews</th>
      </tr>
    </thead>
    <draggable v-model='values' @change='move' v-bind='draggableOptions' tag='tbody'>
      <evaluative-reviews v-for='value in values' :value='value' :key='value.id' :short-identifier='shortIdentifier' class='value'/>
      <tr slot='footer' :class='{ add: hasValues }'>
        <td>
          <add-button :short-identifier='shortIdentifier' :types='types' v-on:open-add-modal='open_add_modal'/>
        </td>
        <td></td>
      </tr>
    </draggable>
  </table>
</template>

<script>
  import ValuesList from '../../mixins/values_list'
  import EvaluativeReviews from './evaluative_reviews.vue'

  export default {
    mixins: [ValuesList],
    components: { EvaluativeReviews }
  }
</script>
