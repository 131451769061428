<template>
  <div v-html='content' class='trix-content' v-if='content'></div>
  <div class='ui fluid placeholder' v-else>
    <div class="header">
      <div class="line"></div>
    </div>
    <div class="paragraph">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: String
    }
  }
</script>
