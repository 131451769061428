<template>
  <a :href='link' v-bind='attributes' v-html='content'></a>
</template>

<script>
  export default {
    props: {
      content: String,
      escaped_content: String,
      link: String,
      open_in: String
    },
    computed: {
      attributes() {
        if (this.open_in === 'preview_box') {
          return {
            'data-fancybox-preview': '',
            'data-caption': this.escaped_content
          }
        } else if (this.open_in === 'new_tab') {
          return { target: '_blank' }
        }
      }
    }
  }
</script>
