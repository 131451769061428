<template>
  <div class="ui celled stackable grid">
    <div class="one column black row">
      <div class="column">
        <h3 class="ui inverted header">{{ identifier }}</h3>
      </div>
    </div>
    <div class="one column row">
      <div class="column">
        <observations :identifier="`${identifier}: Observation`" short-identifier="Observation" types='Text'/>

        <table class='ui celled striped table'>
          <thead>
            <tr>
              <th>Meeting Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <value-list :identifier="`${identifier}: Meeting Note`" short-identifier='Meeting Note' types='Text,Image,Link,File,Drive,Mixed'/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import Observations from './observations.vue'
  import ValueList from '../../value_list.vue'

  export default {
    props: {
      identifier: String
    },
    components: { Observations, ValueList }
  }
</script>
