export default class AttachmentCoordinator {
  constructor() {
    this.pending_attachment_adds =  {}
    this.pending_attachment_removes =  {}
    this.xhrs = []
  }

  addAttachment(attachment) {
    if (attachment.id in this.pending_attachment_removes) {
      delete this.pending_attachment_removes[attachment.id]
    } else if (!this.pending_attachment_adds[attachment.id]) {
      this.pending_attachment_adds[attachment.id] = attachment
    }
  }

  removeAttachment(attachment) {
    if (attachment.id in this.pending_attachment_adds) {
      delete this.pending_attachment_adds[attachment.id]
    } else if (!this.pending_attachment_removes[attachment.id]) {
      this.pending_attachment_removes[attachment.id] = attachment
    }
  }

  processPendingAttachments() {
    let attachment, id
    const promises = []

    for (id in this.pending_attachment_adds) {
      attachment = this.pending_attachment_adds[id]
      promises.push(this.createAttachment(attachment))
    }

    for (id in this.pending_attachment_removes) {
      attachment = this.pending_attachment_removes[id]
      promises.push(this.destroyAttachment(attachment))
    }

    return Promise.all(promises)
  }

  purgePendingAttachments() {
    this.pending_attachment_adds = {}
    this.pending_attachment_removes = {}
  }

  updateAggregateProgress() {
    let length = 0
    let total = 0

    for (const id in this.pending_attachment_adds) {
      const attachment = this.pending_attachment_adds[id]
      length += 1
      total += attachment.getUploadProgress()
    }

    this.totalUploadProgressDelegate.progress({ percent: total / length })
  }

  createAttachment(attachment) {
    const { file } = attachment

    if (file) {
      const formData = new FormData()
      formData.append('attachment[file]', file)
      formData.append('source', 'trix')

      const xhr = new window.XMLHttpRequest()
      this.xhrs.push(xhr)

      const request = $.ajax({
        url: `${this.valueUrlDelegate}/attachments`,
        type: 'POST',
        data: formData,
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
        xhr: () => {
          xhr.upload.addEventListener('progress', event => {
            if (event.lengthComputable) {
              const progress = (event.loaded / event.total) * 100
              attachment.setUploadProgress(progress)
              this.updateAggregateProgress()
            }
          }, false)

          return xhr
        }
      })

      return request.then(data => attachment.setAttributes({
        url: data.attachment.url,
        href: data.attachment.url,
        id: data.attachment.id
      }))
    } else {
      return Promise.resolve()
    }
  }

  destroyAttachment(attachment) {
    const id = attachment.getAttribute('id')

    return $.ajax({
      url: `${this.valueUrlDelegate}/attachments/${id}`,
      type: 'DELETE',
      dataType: 'json',
      data: {
        source: 'trix'
      }
    })
  }

  abort() {
    for (var xhr of this.xhrs) { xhr.abort() }
    this.xhrs = []
  }
}
