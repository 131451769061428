import axios from 'axios'

import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import VuexTurbolinks from 'vuex-turbolinks'

import AttachmentCoordinator from './documents/values/attachments/attachment_coordinator'

import documentModal from './store/document_modal'
import documentReferenceModal from './store/document_reference_modal'
import confirmationModal from './store/confirmation_modal'
import documentViewer from './store/document_viewer'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [VuexTurbolinks],
  modules: {
    documentModal,
    documentReferenceModal,
    confirmationModal,
    documentViewer
  },
  state: {
    documentUrl: null,
    documentRequest: null,
    values: {},
    identifiers: {},
    references: {},
    commentStatistics: {},
    commentsValueId: null,
    referenceData: {},
    attachmentCoordinator: new AttachmentCoordinator,
    valueModalContent: null
  },
  actions: {
    initialise(context, documentUrl) {
      context.commit('setDocumentUrl', documentUrl)
      context.commit('setCommentsValueId')
      context.dispatch('getReferenceData')
      context.dispatch('getCommentStatistics')
      return context.dispatch('getReferences').then(() => context.dispatch('getValues'))
    },
    getValues(context) {
      context.state.documentRequest.get('/values')
      .then(response => context.commit('setValues', response.data))
    },
    getCommentStatistics(context) {
      context.state.documentRequest.get('/comment_statistics')
      .then(response => context.commit('setCommentStatistics', response.data))
    },
    getReferenceData(context) {
      context.state.documentRequest.get('/reference_data')
      .then(response => context.commit('setReferenceData', response.data))
    },
    createValue(context, value) {
      return context.state.documentRequest.post('/values', { value })
      .then(response => {
        context.commit('createValue', response.data)
        return response.data
      })
    },
    updateValue(context, value) {
      return context.state.documentRequest.patch(`/values/${value.id}`, { value })
      .then(function(response) {
        context.commit('updateValue', response.data)
        context.dispatch('applyReferenceChanges', response.data.reference_changes)
      })
    },
    destroyValue(context, value) {
      return context.state.documentRequest.delete(`/values/${value.id}`)
      .then(function(response) {
        context.commit('destroyValue', value)
        context.dispatch('applyReferenceChanges', response.data.reference_changes)
      })
    },
    applyReferenceChanges(context, { additions, updates, removals }) {
      let reference

      for (reference of additions) {
        context.commit('createReference', reference)
        context.dispatch('applyValueChanges', reference.reference_value_changes)
      }
      for (reference of updates) {
        context.commit('updateReference', reference)
        context.dispatch('applyValueChanges', reference.reference_value_changes)
      }
      for (reference of removals) {
        context.commit('destroyReference', reference)
        context.dispatch('applyValueChanges', reference.reference_value_changes)
      }
    },
    applyValueChanges(context, { additions, removals }) {
      let reference_value

      for (reference_value of additions) {
        context.commit('createValue', reference_value)
      }
      for (reference_value of removals) {
        context.commit('destroyValue', reference_value)
      }
    },
    getReferences(context) {
      return context.rootState.documentRequest.get("/references")
      .then(response => context.commit('setReferences', response.data))
    },
    setReference(context, { value, identifiers }) {
      return context.state.documentRequest.patch(`/values/${value.id}/reference`, { reference: { identifiers } })
      .then(function(response) {
        context.commit('updateValue', response.data)
        context.dispatch('applyReferenceChanges', response.data.reference_changes)
      })
    }
  },
  mutations: {
    setDocumentUrl(state, url) {
      state.documentUrl = url
      state.documentRequest = axios.create({ baseURL: url })
    },
    setValues(state, payload) {
      state.values = payload.values
      state.identifiers = payload.identifiers
    },
    setCommentStatistics(state, commentStatistics) {
      state.commentStatistics = commentStatistics
    },
    setReferenceData(state, referenceData) {
      state.referenceData = referenceData
    },
    createValue(state, value) {
      Vue.set(state.values, value.id, value)
      state.identifiers[value.identifier][value.parent_id].push(value.id)
    },
    updateValue(state, value) {
      Vue.set(state.values, value.id, value)
    },
    destroyValue(state, value) {
      const values = state.identifiers[value.identifier][value.parent_id]
      const index = values.indexOf(value.id)
      Vue.delete(values, index)
      Vue.delete(state.values, value.id)
    },
    orderValues(state, payload) {
      Vue.set(state.identifiers[payload.identifier], payload.parent_id, payload.value_ids)
    },
    updateCommentStatistics(state, payload) {
      state.commentStatistics[payload.value_id] = payload.comment_statistics
    },
    decrementUnreadComments(state, value_id) {
      state.commentStatistics[value_id].unread -= 1
    },
    setValueModalContent(state, content) {
      state.valueModalContent = content
    },
    clearValueModalContent(state) {
      state.valueModalContent = null
    },
    setReferences(state, payload) {
      state.references = payload.references
    },
    createReference(state, reference) {
      Vue.set(state.references, reference.id, reference)
    },
    updateReference(state, reference) {
      Vue.set(state.references, reference.id, reference)
    },
    destroyReference(state, reference) {
      Vue.delete(state.references, reference.id)
    },
    setCommentsValueId(state) {
      const commentsValueId = /^#values_([0-9]+)_comments$/.exec(window.location.hash)
      if (commentsValueId) { state.commentsValueId = parseInt(commentsValueId[1]) }
    }
  },
  getters: {
    getValues(state) { return function(identifier, parent_id) {
      if (!state.identifiers[identifier]) {
        Vue.set(state.identifiers, identifier, {})
      }
      if (!state.identifiers[identifier][parent_id]) {
        Vue.set(state.identifiers[identifier], parent_id, [])
      }
      return state.identifiers[identifier][parent_id].map(id => state.values[id])
    } },
    commentStatistics(state) { return function(value_id) {
      if (!state.commentStatistics[value_id]) {
        Vue.set(state.commentStatistics, value_id, { total: 0, unread: 0 })
      }
      return state.commentStatistics[value_id]
    } },
    totalComments(_state, getters) { return value_id => getters.commentStatistics(value_id).total },
    unreadComments(_state, getters) { return value_id => getters.commentStatistics(value_id).unread },
    totalReferences(state, _getters) {
      return reference_id => state.references[reference_id] && state.references[reference_id].reference_value_ids.length
    },
    supportsReferences(state) {
      return Object.keys(state.referenceData).length > 0
    }
  }
})
