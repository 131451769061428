export default [
  {
    title: "Referrals",
    content: "If you like School Performance Management, don't forget to refer other schools to earn $50 Visa Prezzy Cards.",
    target: ".menu .item[title=\"Referrals\"]",
    placement: "bottom",
    xOffset: 20,
    fixedElement: true,
    onNext() {
      document.dispatchEvent(new CustomEvent('closeMenu'))
    }
  },
  {
    title: "Activities",
    content: "You'll receive a notification here when someone comments on your documents or on documents you're subscribed to.",
    target: ".menu .item[title=\"Activities\"]",
    placement: "bottom",
    xOffset: -450,
    arrowOffset: 260,
    fixedElement: true,
    onPrev() {
      document.dispatchEvent(new CustomEvent('openMenu'))
    }
  },
  {
    title: "Get Started",
    content: "This area lists <strong>your</strong> documents. Get started by adding a new document.",
    target: "[data-controller=\"new-document-button\"]",
    placement: "bottom"
  }
]
