<template>
  <a :href='preview_path' class='ui top aligned image letterbox value' :style='style' data-fancybox-preview :data-caption='escaped_title' :title='title'>
    <span v-if='title'>{{ title }}</span>
  </a>
</template>

<script>
  export default {
    props: {
      title: String,
      escaped_title: String,
      thumb_path: String,
      preview_path: String
    },
    computed: {
      style() {
        return `background-image: url('${this.thumb_path}');`
      }
    }
  }
</script>
