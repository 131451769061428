<template>
  <tr>
    <td class='six wide'>
      <enhanced-content :key='value.id' :short-identifier='shortIdentifier' :value='value'></enhanced-content>
    </td>
    <td class='ten wide'>
      <table class='ui small celled striped one column table'>
        <thead>
          <tr>
            <th>{{ child.title }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <value-list :identifier='child.identifier' :parentId='value.id' :short-identifier='child.identifier' :types='child.types'></value-list>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script>
  import EnhancedContent from './enhanced_content.vue'
  import ValueList from './value_list.vue'

  export default {
    props: {
      value: Object,
      shortIdentifier: String,
      child: Object
    },
    components: { EnhancedContent, ValueList }
  }
</script>
