export default {
  mounted() {
    const event = new CustomEvent('documentRendered', {
      detail: null, bubbles: true, cancelable: true
    })

    this.$nextTick(function() {
      this.$el.dispatchEvent(event)
    })
  },
  watch: {
    '$store.state.values': {
      handler() {
        const event = new CustomEvent('documentUpdated', {
          detail: null, bubbles: true, cancelable: true
        })

        this.$nextTick(function() {
          this.$el.dispatchEvent(event)
        })
      }, deep: true
    }
  }
}
