import shared from './shared'

export default  {
  id: "non-administrators-tour",
  showPrevButton: true,
  onEnd: ["viewTour"],
  onClose: ["viewTour"],
  steps: [
    {
      title: "Welcome!",
      content: "Here's a few tips to get you started...",
      target: ".logo.text.item",
      placement: "bottom",
      xOffset: 20,
      fixedElement: true,
      onNext() {
        document.dispatchEvent(new CustomEvent('openMenu'))
      }
    }, {
      title: "Groups",
      content: "You have been set as a group administrator over a group of users. This means you can view their documents through this menu.",
      target: ".menu .item[title=\"Groups\"]",
      placement: "bottom",
      xOffset: 20,
      fixedElement: true,
      onPrev() {
        document.dispatchEvent(new CustomEvent('closeMenu'))
      }
    },
    ...shared
  ]
}
