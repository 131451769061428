import '@fancyapps/fancybox'
import '@fancyapps/fancybox/dist/jquery.fancybox.css'

$.fancybox.defaults.buttons = ['zoom', 'slideShow', 'fullScreen', 'download', 'close']

// We don't preview html anymore, just redirect to the page if it can't be previewed as an image etc...
$.fancybox.defaults.afterLoad = function(instance, slide) {
  if (!slide.redirecting && slide.$slide.hasClass('fancybox-slide--error')) {
    slide.redirecting = true
    instance.setContent(slide, '<div class="fancybox-error"><p>Redirecting...</p></div>')
    window.location = slide.src
  }
}

$.fancybox.defaults.btnTpl.external =
  '<a data-fancybox-external class="fancybox-button fancybox-button--external" title="Open External Link" href="javascript:;">' +
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7,10L5,10L5,19L10,19L10,17L7,17L7,10ZM5,10L7,10L7,7L10,7L10,5L5,5L5,10ZM17,17L10,17L10,19L19,19L19,14L17,14L17,17ZM13,5L13,7L15.482,7L8,14L9.427,15.841L17,8.5L17,11L19,11L19,5L13,5Z"/></svg>' +
  '</a>'

$(document).on('click', '[data-fancybox-preview]', function(event) {
  event.preventDefault()

  $.fancybox.open({
    src: $(this).attr('href'),
    caption: $(this).attr('data-caption')
  })
})

$(document).on('click', '[data-fancybox-embed]', function(event) {
  event.preventDefault()

  const $embed = $(this)

  $.fancybox.open({
    src: $embed.attr('href'),
    type: 'iframe',
    afterLoad: $.noop,
    buttons: ['fullScreen', 'external', 'close'],
    beforeShow(instance) {
      const $container = instance.$refs.container
      $container.find('[data-fancybox-external]').attr("href", $embed.data('link'))
    }
  })
})

$(document).on('click', ':not(trix-editor).trix-content figure.attachment-preview, :not(trix-editor).trix-content :not(.attachment-gallery) figure.attachment--preview', function(event) {
  event.preventDefault()

  $.fancybox.open({
    src: $(this).find('img').attr('src'),
    caption: $(this).find('figcaption').html()
  })
})

$(document).on('click', ':not(trix-editor).trix-content .attachment-gallery figure.attachment--preview', function(event) {
  event.preventDefault()

  const sources = $(this).siblings().addBack().map(function() {
    return {
      src: $(this).find('img').attr('src'),
      caption: $(this).find('figcaption').html()
    }
  })

  $.fancybox.open(sources, { index: $(this).index() })
})
