const defaultState = () => ({
  isVisible: false,
  iconClass: 'question',
  title: null,
  prompt: null,
  positiveAction() {},
  negativeAction() {},
  positiveActionLabel: 'Yes',
  negativeActionLabel: 'No'
})

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    show(state, payload) {
      Object.assign(state, payload)
      state.isVisible = true
    },
    approve(state) {
      state.positiveAction()
      state.isVisible = false
    },
    deny(state) {
      state.negativeAction()
      state.isVisible = false
    },
    reset(state) {
      Object.assign(state, defaultState())
    }
  }
}
