<template>
  <div v-html='content' class='trix-content' ></div>
</template>

<script>
  export default {
    props: {
      content: String
    }
  }
</script>
