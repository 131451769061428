<template>
  <div class='sixteen wide column'>
    <enhanced-date :key='value.id' :short-identifier='shortIdentifier' :value='value'></enhanced-date>
    <table class='ui celled striped table'>
      <thead>
        <tr>
          <th class='six wide'>Discussion</th>
          <th class='six wide'>Further Support and Development</th>
          <th class='four wide'>Signed</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <value-list :parentId='value.id' identifier='Discussion' short-identifier='Record' types='Text,Image,Link,File,Drive,Mixed'></value-list>
          </td>
          <td>
            <value-list :parentId='value.id' identifier='Further Support and Development' short-identifier='Record' types='Text,Image,Link,File,Drive,Mixed'></value-list>
          </td>
          <td>
            <value-list :parentId='value.id' :value-component='signatureComponent' identifier='Signature' short-identifier='Signature' types='Signature'></value-list>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import EnhancedDate from './enhanced_date.vue'
  import Signature from './signature.vue'
  import ValueList from '../value_list.vue'

  export default {
    data() {
      return { signatureComponent: Signature }
    },
    props: {
      value: Object,
      index: Number,
      shortIdentifier: String
    },
    components: { EnhancedDate, ValueList }
  }
</script>
