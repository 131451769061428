<template>
	<draggable @change='move' v-bind='draggableOptions' v-model='values'>
		<component :is='valueComponent' :key='value.id' :short-identifier='shortIdentifier' :value='value' class='value' v-for='value in values' :commentable="false"></component>
	</draggable>
</template>

<script>
  import ValuesList from '../mixins/values_list'

  export default {
    mixins: [ValuesList]
  }
</script>
