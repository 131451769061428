export default {
  id: "principal-document-tour",
  showPrevButton: true,
  onEnd: ["viewTour"],
  onClose: ["viewTour"],
  steps: [{
    title: "Evidence Portfolio: Principal",
    content: "This is the portfolio that you will use to meet one of the requirements to renew your PTC (Practising Teacher Certificate) on a three yearly cycle.",
    target: "titlepage",
    placement: "bottom",
    xOffset: "center",
    arrowOffset: "center"
  }
  , {
    title: "Resource Links",
    content: "Use these links to gain further information from the Teaching Council website about appraisal.",
    target: "resource-links",
    placement: "top"
  }
  , {
    title: "Areas of Practice",
    content: "For leaders of learners, the first four sections in the portfolio reflect the four Professional Standards from the Primary Principals' Collective Agreement for appraisal purposes and for renewing your PTC.",
    target: "[data-title=\"Area of Practice: Culture\"]",
    placement: "top"
  }
  , {
    title: "Links to Other Standards",
    content: "In this portfolio these sections also have the relevant links to the Teaching Council Criteria and Tātaiako.",
    target: "[data-title=\"Links\"]",
    placement: "bottom",
    xOffset: "center",
    arrowOffset: "center"
  }
  , {
    title: "Add a New Indicator",
    content: "Record your indicators of professional practice that you focus on under each Professional Standard.",
    target: "[data-title=\"Indicators\"]",
    placement: "top"
  }
  , {
    title: "Special Character Principal Indicators",
    content: "If you are the Principal of a Special Character school, this is where you record your Special Character indicators and evidence of your professional practice in achieving these indicators.",
    target: "[data-title=\"Indicators\"]",
    placement: "top"
  }
  , {
    title: "Add a New Evidence",
    content: "Record evidence of your practice in achieving these indicators. Once you add a piece of evidence, you can edit it by clicking the <i class=\"fitted grey write icon\"></i> icon next to it.",
    target: "[data-title=\"Evidence\"]",
    placement: "top"
  }
  , {
    title: "Data Types",
    content: "Depending on where you are adding data to your document you may be able to add all or a subset of: <strong>Text</strong>, <strong>Image</strong>, <strong>Link</strong>, <strong>File</strong>, and <strong>Mixed</strong> types. Experiment with each of these to get a feel for how they work.",
    target: "[data-title=\"Evidence\"]",
    placement: "top"
  }
  , {
    title: "Colouring your Evidence",
    content: "Clicking the <i class=\"fitted grey write icon\"></i> icon and hovering the <strong>Colour</strong> menu will allow you to select a colour to highlight your evidence with. You can use this to indicate evidence that is relevant for a certain year for example.",
    target: "[data-title=\"Evidence\"]",
    placement: "top"
  }
  , {
    title: "Identified Areas for Development",
    content: "This is the section where you record areas for development identified for further reflection by your appraiser as part of your new cycle of appraisal. Record indicators, and evidence beneath each area.",
    target: "[data-title=\"Identified Areas for Development\"]",
    placement: "top"
  }
  , {
    title: "Teaching as Inquiry Research Questions",
    content: "In developing your 'spiral of inquiry' question(s) remember to reference the relevant Standards to ensure that the evidence you are gathering relates to the Professional Standard you are focused on. The evidence gathered should provide answers to your Inquiry Reflective Questions and inquiry outcome.",
    target: "[data-title=\"Teaching as Inquiry Research Questions\"]",
    placement: "top"
  }
  , {
    title: "Identified Areas for Development from Previous Appraisals",
    content: "Record any goals and indicators that have been identified for review in previous appraisals. Provide evidence that change has occurred.",
    target: "[data-title=\"Identified Areas for Development from Previous Appraisals\"]",
    placement: "top"
  }
  , {
    title: "Māori / Pasifika Goals, School Performance Goals, and Professional Development Goals",
    content: "Record your indicators of practice, and evidence that reflect your school goals (as established through your Charter and Strategic Plan). These goals are confirmed with your appraiser at the beginning of each cycle.",
    target: "[data-title=\"Māori / Pasifika Goals\"]",
    placement: "top"
  }
  , {
    title: "Professional Learning and Development Records",
    content: "Record evidence of other areas of professional practice that are not specifically related to your professional development goals.",
    target: "[data-title=\"Professional Learning and Development Records\"]",
    placement: "top"
  }
  , {
    title: "Appraisal Interview Notes",
    content: "Record a variety of evidence curated by your appraiser to inform your practice and professional development.",
    target: "[data-title=\"Appraisal Interview Notes\"]",
    placement: "top"
  }
  , {
    title: "Reference Documents",
    content: "Upload specific documents that inform your appraisal such as: School Charter, Strategic Plan, Job Description, Annual Plan, Curriculum Learning Plan, Assessment Guide.",
    target: "[data-title=\"Reference Documents\"]",
    placement: "top"
  }
  , {
    title: "Thank You! :)",
    content: "Thanks for using School Performance Management!",
    target: "#titlepage h1.header",
    placement: "bottom",
    xOffset: "center",
    arrowOffset: "center"
  }
  ]
}
