const defaultState = () => ({
  isVisible: false,
  header: '',
  value: {}
})

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    show(state, payload) {
      Object.assign(state, payload)
      state.isVisible = true
    },
    hide(state) {
      state.isVisible = false
    },
    reset(state) {
      Object.assign(state, defaultState())
    }
  }
}
