import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const $stickyIndex = $(this.element)

    $stickyIndex.sticky({
      context: '#sticker',
      offset: 74,
      observeChanges: true
    })
  }

  scrollTo(event) {
    const $target = $(event.target)

    if($target.is('a.item')) {
      event.preventDefault()

      const href = $target.attr('href')

      $('html, body').animate({
        scrollTop: $(href).offset().top - 80
      }, 500)
    }
  }
}
