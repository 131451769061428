<template>
  <trix-editor class='trix-content top attached' input='mixed_value_content' autofocus v-pre></trix-editor>
</template>

<script>
  import Store from '../../../store'

  export default {
    mounted() {
      $(document).on('trix-attachment-add', function(event) {
        const { attachment } = event.originalEvent
        Store.state.attachmentCoordinator.addAttachment(attachment)
      })

      $(document).on('trix-attachment-remove', function(event) {
        const { attachment } = event.originalEvent
        Store.state.attachmentCoordinator.removeAttachment(attachment)
      })
    },
    beforeDestroy() {
      $(document).off('trix-attachment-add')
      $(document).off('trix-attachment-remove')
    }
  }
</script>

<style lang="scss" scoped>
  trix-editor {
    height: 24em;
    min-height: 8em;
    max-height: 24em;
    overflow-y: auto;
  }
</style>
