<template>
  <div class='ui basic modal'>
    <div class='ui icon header'>
      <i class='icon' :class='modal.iconClass'></i>
      {{ modal.header }}
    </div>
    <div class='content'>
      <p>{{ modal.prompt }}</p>
    </div>
    <div class='actions'>
      <div class='ui positive green inverted button'>
        <i class="checkmark icon"></i>
        {{ modal.positiveActionLabel }}
      </div>
      <div class='ui negative red basic inverted button'>
        <i class="remove icon"></i>
        {{ modal.negativeActionLabel }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      modal() {
        return this.$store.state.confirmationModal
      },
      isVisible() {
        return this.modal.isVisible
      }
    },
    mounted() {
      $(this.$el).modal({
        closable: false,
        context: '.modal.context',
        onApprove: () => {
          this.$store.commit('confirmationModal/approve')
          return false
        },
        onDeny: () => {
          this.$store.commit('confirmationModal/deny')
          return false
        },
        onHidden: () => {
          this.$store.commit('confirmationModal/reset')
        }
      })
    },
    watch: {
      isVisible(isVisible) {
        if (isVisible) {
          $(this.$el).modal('show')
        } else {
          $(this.$el).modal('hide')
        }
      }
    }
  }
</script>
