<template>
  <a :href='link' target="_blank" v-html='content'></a>
</template>

<script>
  export default {
    props: {
      content: String,
      escaped_content: String,
      link: String,
      embed_url: String
    }
  }
</script>
