<template>
  <div class='sixteen wide column'>
    <enhanced-date :key='value.id' :short-identifier='shortIdentifier' :value='value'></enhanced-date>
    <table class='ui celled striped table'>
      <thead>
        <tr>
          <th class='four wide'>In-class Support / Observations</th>
          <th class='four wide'>Visits / Observations</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <value-list :parentId='value.id' identifier='In-class Support / Observations' short-identifier='Record' types='Text,Image,Link,File,Drive,Mixed'></value-list>
          </td>
          <td>
            <value-list :parentId='value.id' identifier='Visits / Observations' short-identifier='Record' types='Text,Image,Link,File,Drive,Mixed'></value-list>
          </td>
        </tr>
      </tbody>
    </table>
    <table class='ui celled striped table'>
      <thead>
        <tr>
          <th class='four wide'>Discussions / Meetings</th>
          <th class='four wide'>Administration</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <value-list :parentId='value.id' identifier='Discussions / Meetings' short-identifier='Record' types='Text,Image,Link,File,Drive,Mixed'></value-list>
          </td>
          <td>
            <value-list :parentId='value.id' identifier='Administration' short-identifier='Record' types='Text,Image,Link,File,Drive,Mixed'></value-list>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import EnhancedDate from './enhanced_date.vue'
  import ValueList from '../value_list.vue'

  export default {
    props: {
      value: Object,
      index: Number,
      shortIdentifier: String
    },
    components: { EnhancedDate, ValueList }
  }
</script>
