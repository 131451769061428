<template>
  <tr>
    <td>
      <enhanced-content :value='value' :key='value.id' :short-identifier='shortIdentifier'/>
    </td>
    <td>
      <value-list identifier='Evaluative Review' short-identifier='Review' types='Text,File,Drive' :parentId='value.id'/>
    </td>
  </tr>
</template>

<script>
  import EnhancedContent from '../../enhanced_content.vue'
  import ValueList from '../../value_list.vue'

  export default {
    props: {
      value: Object,
      shortIdentifier: String
    },
    components: { EnhancedContent, ValueList }
  }
</script>
