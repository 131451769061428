<template>
  <div class='ui fluid accordion field' ref='standards'>
    <label>{{ title }}</label>
    <template v-for='standard in standards'>
      <h4 class='ui heading title' ref='titles'>
        <i class='icon dropdown'></i>
        {{ standard.title }}
      </h4>
      <div class='content grouped fields' ref='contents'>
        <label>{{ standard.content }}</label>
        <div v-for='{content, identifier} in standard.indicators' class='field'>
          <div class='ui checkbox' ref='checkboxes'>
            <input type='checkbox' :value='identifier' v-model='localIdentifiers'>
            <label v-html='content'></label>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      standards: Array,
      identifiers: Array
    },
    computed: {
      localIdentifiers: {
        get() {
          return this.identifiers
        },
        set(value) {
          this.$emit('update:identifiers', value)
        }
      }
    },
    mounted() {
      $(this.$refs.standards).accordion({
        exclusive: false,
        animateChildren: false
      })
      $(this.$refs.checkboxes).checkbox()
    },
    methods: {
      show() {
        this.standards.map((standard, index) => {
          for (const identifier of this.localIdentifiers) {
            if (standard.identifiers.includes(identifier)) {
              $(this.$refs.titles).eq(index).addClass('active')
              $(this.$refs.contents).eq(index).addClass('active')
            }
          }
        })
      },
      hide() {
        $(this.$refs.titles).removeClass('active')
        $(this.$refs.contents).removeClass('active')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ui.form {
    > .accordion.field {
      .ui.heading {
        margin: 0;
        padding: 0.5em 0;
      }

      > .grouped.fields {
        margin-bottom: 0;
        margin-left: 1.5em;

        > .field > .ui.checkbox {
          margin: 0;
        }
      }
    }
  }
</style>
