<template>
  <div class='ui segment'>
    <i class='fitted inverted grey ellipsis horizontal handle icon'></i>
    <div class='ui top right pointing value dropdown'>
      <i class='fitted grey write icon' title='Actions'></i>
      <div class='menu'>
        <div @click='destroy' class='item'>
          <i class='delete icon'></i>
          Destroy
        </div>
        <div class='blue header' v-bind:title='value.updated_at'>
          <i class='clock icon'></i>
          Updated: {{ formatted_date(value.updated_at) }}
        </div>
      </div>
    </div>
    <component v-bind='value.component'></component>
  </div>
</template>

<script>
  import DateFormatter from '../../date_formatter'
  import ValueDestroyer from '../../documents/values/value_destroyer'

  import SignatureValue from '../values/signature.vue'

  export default {
    props: {
      value: Object,
      shortIdentifier: String
    },
    methods: {
      formatted_date(date) {
        return DateFormatter.format(new Date(date))
      },
      destroy() {
        new ValueDestroyer(this).destroyValue()
      }
    },
    mounted() {
      $(this.$el).find('.ui.dropdown').dropdown({ action: 'hide' })
    },
    components: {
      SignatureValue
    }
  }
</script>
