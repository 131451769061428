<template>
  <tr>
    <td>
      <enhanced-content :value='value' :key='value.id' :short-identifier='shortIdentifier'></enhanced-content>
    </td>
    <td>
      <value-list :identifier='child.identifier' :short-identifier='child.identifier' :types='child.types' :parentId='value.id'></value-list>
    </td>
  </tr>
</template>

<script>
  import EnhancedContent from './enhanced_content.vue'
  import ValueList from './value_list.vue'

  export default {
    props: {
      value: Object,
      shortIdentifier: String,
      child: Object
    },
    components: { EnhancedContent, ValueList }
  }
</script>
