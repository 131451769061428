import Draggable from 'vuedraggable'
import EnhancedContent from '../enhanced_content.vue'
import AddValueCoordinator from '../../documents/values/add_value_coordinator'
import ValueMover from '../../documents/values/value_mover'

export default {
  props: {
    identifier: String,
    shortIdentifier: String,
    templateConfigurationValueId: String,
    types: String,
    parentId: {
      type: Number,
      default: 0
    },
    valueComponent: {
      type: [String, Object],
      default() { return 'enhanced-content' }
    }
  },
  data() {
    return {
      draggableOptions: {
        handle: ".handle",
        scrollSensitivity: 80,
        draggable: '.value'
      }
    }
  },
  computed: {
    hasValues() {
      return this.values.length > 0
    },
    values: {
      get() {
        return this.$store.getters.getValues(this.identifier, this.parentId)
      },
      set(values) {
        this.$store.commit('orderValues', {
          identifier: this.identifier,
          parent_id: this.parentId,
          value_ids: (values.map((value) => value.id))
        })
      }
    }
  },
  methods: {
    move({moved}) {
      new ValueMover(this).moveValue(moved.element, moved.newIndex)
    },
    open_add_modal(type) {
      new AddValueCoordinator(this.shortIdentifier, {
        identifier: this.identifier,
        parentId: (this.parentId === 0 ? null : this.parentId),
        templateConfigurationValueId: this.templateConfigurationValueId,
        type
      })
    }
  },
  components: { Draggable, EnhancedContent }
}
