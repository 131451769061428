<template>
  <div class='one column row'>
    <div class='column'>
      <table class='ui celled striped table' v-if='hasValues'>
        <draggable @change='move' tag='tbody' v-bind='draggableOptions' v-model='values'>
          <appraisal-area :key='value.id' :short-identifier='shortIdentifier' :value='value' class='value' v-for='value in values'></appraisal-area>
        </draggable>
      </table>
      <add-button :short-identifier='shortIdentifier' :types='types' v-on:open-add-modal='open_add_modal'></add-button>
    </div>
  </div>
</template>

<script>
  import ValuesList from './mixins/values_list'
  import AppraisalArea from './appraisal_area.vue'

  export default {
    mixins: [ValuesList],
    components: { AppraisalArea }
  }
</script>
