<template>
  <a @click.stop='view' v-if='value.type === "Document"'>{{ title }}</a>
  <component v-else v-bind='value.component'></component>
</template>

<script>
  import TextValue from './text.vue'
  import ImageValue from './image.vue'
  import LinkValue from './link.vue'
  import FileValue from './file.vue'
  import DriveValue from './drive.vue'
  import MixedValue from './mixed.vue'

  export default {
    props: {
      reference_id: Number
    },
    computed: {
      reference() {
        return this.$store.state.references[this.reference_id]
      },
      value() {
        return this.$store.state.values[this.reference.value_id]
      },
      component() {
        return this.value.component
      },
      title() {
        return this.reference.title || this.component.title || 'Untitled document'
      },
      escapedTitle() {
        return this.reference.escaped_title || this.component.escaped_title
      },
      content() {
        return this.component.content
      }
    },
    methods: {
      view() {
        this.$store.commit('documentViewer/show', {
          content: this.content,
          title: this.escapedTitle,
          referenceId: this.reference_id
        })
      }
    },
    components: {
      TextValue,
      ImageValue,
      LinkValue,
      FileValue,
      DriveValue,
      MixedValue
    }
  }
</script>

<style scoped>
  a {
    cursor: pointer;
  }
</style>
