<template>
  <div class='add button container'>
    <a class='ui tiny labeled icon green add button' :class='dropdownClasses' @click='openDefaultAddModal'>
      <i class='plus icon'></i>
      Add {{ shortIdentifier }}

      <div class='menu' v-if='!hasSingleType'>
        <div class='item' v-for='type in splitTypes' :data-value='type'>
          <i class='icon' :class='typeIcons[type]'></i>
          {{ type }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        typeIcons: {
          'Text': 'align left',
          'Image': 'photo',
          'Link': 'linkify',
          'File': 'upload',
          'Drive': 'google drive',
          'Mixed': 'paint brush',
          'Date': 'calendar alternate',
          'Signature': 'check',
          'Document': 'file alternate'
        }
      }
    },
    props: {
      shortIdentifier: String,
      types: String
    },
    computed: {
      splitTypes() {
        return this.types.split(',')
      },
      hasSingleType() {
        return this.splitTypes.length === 1
      },
      dropdownClasses() {
        return { 'top left pointing dropdown': !this.hasSingleType }
      }
    },
    methods: {
      openAddModal(type) {
        this.$emit('open-add-modal', type)
      },
      openDefaultAddModal() {
        if (this.hasSingleType) {
          const type = this.splitTypes[0]
          this.openAddModal(type)
        }
      }
    },
    mounted() {
      if (!this.hasSingleType) {
        this.$nextTick(() => {
          $(this.$el).find('> a.dropdown').dropdown({
            action: 'hide',
            onChange: (type) => { this.openAddModal(type) }
          })
        })
      }
    }
  }
</script>
