<template>
  <div v-if='value'>
    <div :style='backgroundImage' :title='value.component.title' class='ui top aligned image portrait value'></div>
    <div class='ui top right pointing value dropdown'>
      <i class='fitted grey write icon' title='Actions'></i>
      <div class='menu'>
        <div @click='open_edit_modal' class='item'>
          <i class='edit icon'></i>
          Edit
        </div>
        <div @click='destroy' class='item'>
          <i class='delete icon'></i>
          Destroy
        </div>
        <div class='blue header' v-bind:title='value.updated_at'>
          <i class='clock icon'></i>
          Updated: {{ formatted_date(value.updated_at) }}
        </div>
      </div>
    </div>
  </div>
  <div @click='open_add_modal' class='ui labeled tiny green icon button' v-else-if='!value'>
    <i class='plus icon'></i>
    Add Photo
  </div>
</template>

<script>
import DateFormatter from '../../date_formatter'
import AddValueCoordinator from '../../documents/values/add_value_coordinator'
import EditValueCoordinator from '../../documents/values/edit_value_coordinator'
import ValueDestroyer from '../../documents/values/value_destroyer'

export default {
  props: {
    shortIdentifier: String,
    identifier: String
  },
  methods: {
    formatted_date(date) {
      return DateFormatter.format(new Date(date))
    },
    open_add_modal() {
      new AddValueCoordinator(this.shortIdentifier, {
        identifier: this.identifier,
        type: 'Image'
      })
    },
    open_edit_modal() {
      new EditValueCoordinator(this.shortIdentifier, { value: this.value })
    },
    destroy() {
      new ValueDestroyer(this).destroyValue()
    }
  },
  computed: {
    value() {
      const value = this.$store.getters.getValues(this.identifier, 0)
      if (value) { return value[0] }
    },
    backgroundImage() {
      return `background-image: url('${this.value.component.thumb_path}');`
    }
  },
  updated() {
    $(this.$el).find('.ui.dropdown').dropdown({ action: 'hide' })
  }
}
</script>
