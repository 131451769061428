<template>
  <div class='single line'>{{ content }}</div>
</template>

<script>
  export default {
    props: {
      content: String
    }
  }
</script>
