<template>
  <div class='ui value modal'>
    <div class='header'></div>
    <div class='content'>
      <value-modal-content :content='valueModalContent'></value-modal-content>
    </div>
    <div class='actions'>
      <div class='ui positive button'></div>
      <div class='ui negative button'>Cancel</div>
    </div>
  </div>
</template>

<script>
  import ValueModalContent from './value_content'

  export default {
    computed: {
      valueModalContent() {
        return this.$store.state.valueModalContent
      }
    },
    components: {
      ValueModalContent
    }
  }
</script>
