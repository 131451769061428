import Store from '../../store'
import Vue from 'vue/dist/vue.esm'

export default class ValueCoordinator {
  constructor(shortIdentifier) {
    this.establishModal = this.establishModal.bind(this)
    this.activateContent = this.activateContent.bind(this)
    this.shortIdentifier = shortIdentifier
    this.documentUrl = Store.state.documentUrl
    this.attachmentCoordinator = Store.state.attachmentCoordinator

    this.modal = $('.ui.value.modal')

    this.approveButton = this.modal.find('.actions .positive.button')

    this.xhr = new window.XMLHttpRequest()
  }

  establishModal(data) {
    this.setHeader()
    this.attachmentCoordinator.valueUrlDelegate = `${this.documentUrl}/values/${this.id}`

    this.modal.modal({
      selector: {
        approve: '.actions .positive:not(.disabled)'
      },
      closable: false,
      context: '.modal.context',
      autofocus: false,
      onShow: () => {
        this.setContent(data)
      },
      onApprove: () => {
        this.disableForm()

        this.attachmentCoordinator.processPendingAttachments()
        .then(() => {
          const form = this.modal.find('form')
          let formData = new FormData(form[0])
          formData = this.setAdditionalFormData(formData)

          return this.sendValue(form, formData)
        }).then(content => {
          this.modal.modal('hide')
          this.updatePage(content)
        }).catch(jqXHR => {
          this.xhr = new window.XMLHttpRequest()
          if (jqXHR.readyState === 0) {
            alert('An error has occurred and your data was not saved. Please try again.')
          } else if (jqXHR.readyState === 4) {
            this.setContent(jqXHR.responseText)
          }
        }).then(() => {
          this.enableForm()
        })

        return false
      },
      onDeny: () => {
        this.xhr.abort()
        this.attachmentCoordinator.abort()
        this.modal.modal('hide')
      },
      onHide: () => {
        this.attachmentCoordinator.purgePendingAttachments()
      },
      onHidden: () => {
        this.clearContent()
      }
    }).modal('show')
  }

  setContent(html) {
    Store.commit('setValueModalContent', html)
    Vue.nextTick(() => {
      this.activateContent()
    })
  }

  clearContent() {
    Store.commit('clearValueModalContent')
  }

  activateContent() {
    this.attachmentCoordinator.totalUploadProgressDelegate = this.modal.find('.trix.progress')

    this.modal.find('select.dropdown').dropdown()
  }

  setAdditionalFormData(formData) {
    return formData
  }

  sendValue(form, formData) {
    return $.ajax({
      url: form.attr('action'),
      type: form.attr('method'),
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      xhr: () => {
        const progress_bar = form.find('.file.browser .progress')

        if (progress_bar) {
          this.xhr.upload.addEventListener('progress', function(event) {
            if (event.lengthComputable) {
              progress_bar.progress({percent: (event.loaded / event.total) * 100})
            }
          }
          , false)
        }

        return this.xhr
      }
    })
  }

  disableForm() {
    this.approveButton.addClass('disabled')
  }

  enableForm() {
    this.approveButton.removeClass('disabled')
  }
}
