<template>
  <a :href='thumb_path || path' v-bind='attributes' v-html='content'></a>
</template>

<script>
  export default {
    props: {
      content: String,
      escaped_content: String,
      thumb_path: String,
      path: String
    },
    computed: {
      attributes() {
        if (this.thumb_path) {
          return {
            'data-fancybox-preview': '',
            'data-caption': this.escaped_content
          }
        } else {
          return { 'data-turbolinks': 'false' }
        }
      }
    }
  }
</script>
