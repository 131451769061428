<template>
  <div>
    <trix-toolbar id='text_value_toolbar'>
      <div class="trix-button-row">
        <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="Bold" tabindex="-1">Bold</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="Italic" tabindex="-1">Italic</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="Strike" tabindex="-1">Strike</button>
        </span>

        <span class="trix-button-group-spacer"></span>

        <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="Undo" tabindex="-1">Undo</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="Redo" tabindex="-1">Redo</button>
        </span>
      </div>
    </trix-toolbar>

    <trix-editor class='trix-content top attached' toolbar='text_value_toolbar' input='text_value_content' autofocus v-pre></trix-editor>
  </div>
</template>

<script>
  export default {
    mounted() {
      $(document).on('trix-file-accept', event => event.preventDefault())
    },
    beforeDestroy() {
      $(document).off('trix-file-accept')
    }
  }
</script>

<style lang="scss" scoped>
  trix-editor {
    height: 12em;
    min-height: 8em;
    max-height: 12em;
    overflow-y: auto;
  }
</style>
