<template>
  <div class='file browser field'>
    <div class='ui fluid action top attached input'>
      <input :value='fileName' disabled name='name' placeholder='Browse...' type='text'>
      <div @click='browseDevice' class='ui right icon blue browse button' data-inverted data-tooltip='Browse Device'>
        <i class='folder open icon'></i>
      </div>
      <div :disabled='!pickerApiLoaded' @click='browseGoogleDrive' class='ui right icon yellow drive button' data-inverted data-tooltip='Browse Google Drive'>
        <i class='google drive icon'></i>
      </div>
    </div>
    <div class='ui bottom attached progress'>
      <div class='bar'></div>
    </div>
    <input @change='assignBrowsedFile' name='value[file]' ref='fileInput' type='file'>
    <input :value='googleDrive.fileId' class='hidden' name='value[google_drive_file_id]' type='hidden'>
    <input :value='googleDrive.fileName' class='hidden' name='value[google_drive_file_name]' type='hidden'>
    <input :value='oauthToken' class='hidden' name='value[google_drive_oauth_token]' type='hidden'>
  </div>
</template>

<script>
  export default {
    props: {
      existingName: String,
      viewId: {
        validator(value) {
          return ['DOCS', 'DOCS_IMAGES', 'DOCS_VIDEOS'].includes(value)
        }
      }
    },
    data() {
      return {
        client: null,
        fileName: null,
        googleDrive: {
          fileId: null,
          fileName: null
        },
        picked: {},
        pickerApiLoaded: false,
        oauthToken: null,
        config: {
          clientId: '313527239949-nmurf7c1rr96aac0jn09mmofeo0hovd5.apps.googleusercontent.com',
          scope: 'https://www.googleapis.com/auth/drive.file',
          developerKey: 'AIzaSyAu4kLPDeVLakQ2Zgcbrs56wwKkBK8ORdA'
        }
      }
    },
    created() {
      this.fileName = this.existingName
    },
    mounted() {
      this.client = google.accounts.oauth2.initTokenClient({
        client_id: this.config.clientId,
        scope: this.config.scope,
        callback: this.handleAuthResult,
        prompt: ''
      })

      gapi.load('picker', () => {
        this.pickerApiLoaded = true
      })
    },
    methods: {
      browseGoogleDrive() {
        this.client.requestAccessToken()
      },
      handleAuthResult(authResult) {
        if (authResult && !authResult.error) {
          this.oauthToken = authResult.access_token
          this.createPicker()
        }
      },
      createPicker() {
        if (this.pickerApiLoaded && this.oauthToken) {
          const picker = new google.picker.PickerBuilder()
            .addView(google.picker.ViewId[this.viewId])
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .setOAuthToken(this.oauthToken)
            .setDeveloperKey(this.config.developerKey)
            .setCallback(this.pickerCallback)
            .build()

          picker.setVisible(true)
        }
      },
      pickerCallback(data) {
        if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
          const file = data[google.picker.Response.DOCUMENTS][0]

          this.fileName = file.name
          this.googleDrive.fileId = file.id
          this.googleDrive.fileName = file.name
          this.$refs.fileInput.value = ''
        }
      },
      browseDevice() {
        this.$refs.fileInput.click()
      },
      assignBrowsedFile() {
        const file = this.$refs.fileInput.files[0]

        this.fileName = file.name
        this.googleDrive.fileId = null
        this.googleDrive.fileName = null
      }
    }
  }
</script>
