<template>
  <div class='ui basic fullscreen modal'>
    <div class='title'>
      <div class='ui huge inverted transparent fluid left icon input'>
        <input v-model='value.title' type='text' placeholder='Untitled document'>
        <i class='file alternate icon'></i>
      </div>
    </div>
    <div id='froala_toolbar'></div>
    <div class='content' id='froala_content'>
      <div ref='content'></div>
    </div>
    <div class='header'>
      {{ modal.header }}
    </div>
    <div class='actions'>
      <div class='ui positive button' ref='approve_button'>Save</div>
      <div class='ui negative button'>Cancel</div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import compact from 'lodash/compact'
  import join from 'lodash/join'

  import froalaConfig from '../../../froala/config'

  export default {
    data() {
      return {
        value: {},
        initialised: false
      }
    },
    computed: {
      modal() {
        return this.$store.state.documentModal
      },
      isVisible() {
        return this.modal.isVisible
      },
      request() {
        return axios.create({ baseURL: this.$store.state.documentUrl })
      },
      unsavedValue() {
        return this.$storage.get(this.localStorageKey())
      },
      savedValue() {
        return {
          id: this.modal.value.id,
          title: this.modal.value.component.title,
          content: this.modal.value.component.content
        }
      }
    },
    mounted() {
      const $content = $(this.$refs.content)

      $(this.$el).modal({
        selector: {
          approve: '.actions .positive:not(.disabled)'
        },
        closable: false,
        allowMultiple: true,
        context: '.modal.context',
        onShow: () => {
          $content.on('froalaEditor.initialized', () => {
            this.initialised = true
          })
          $content.on('froalaEditor.destroy', () => {
            this.initialised = false
          })
          $content.on('froalaEditor.file.inserted', (_event, _editor, $file, response) => {
            const parsedResponse = JSON.parse(response)
            $file.attr('data-attachment-id', parsedResponse['attachment-id'])
          })
          $content.froalaEditor(Object.assign({}, froalaConfig, {
            requestHeaders: {
              'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            fileUploadURL: `${this.$store.state.documentUrl}/values/${this.value.id}/attachments.json`,
            imageUploadURL: `${this.$store.state.documentUrl}/values/${this.value.id}/attachments.json`,
            videoUploadURL: `${this.$store.state.documentUrl}/values/${this.value.id}/attachments.json`
          }))
          $content.froalaEditor('html.set', this.value.content)
          $content.on('froalaEditor.contentChanged', (_event, editor) => {
            this.value.content = editor.html.get()
          })
        },
        onDeny: () => {
          this.$store.commit('documentModal/hide')

          return false
        },
        onHidden: () => {
          $content.froalaEditor('destroy')
          this.$store.commit('documentModal/reset')
        },
        onApprove: () => {
          this.disableForm()

          this.$store.dispatch('updateValue', this.value).then(() => {
            this.$storage.remove(this.localStorageKey())
            this.$store.commit('documentModal/hide')
          }).catch(error => {
            console.log(error.message)
            alert('Something went wrong, please try again.')
          }).then(() => {
            this.enableForm()
          })

          return false
        }
      })
    },
    watch: {
      isVisible(isVisible) {
        if (isVisible) {
          this.setValue().then(value => {
            this.value = value
          }).then(() => {
            $(this.$el).modal('show')
          })
        } else {
          $(this.$el).modal('hide')
        }
      },
      value: {
        deep: true,
        handler(value) {
          if (this.initialised) {
            this.$storage.set(this.localStorageKey(), value)
          }
        }
      }
    },
    methods: {
      disableForm() {
        $(this.$refs.approve_button).addClass('disabled')
      },
      enableForm() {
        $(this.$refs.approve_button).removeClass('disabled')
      },
      localStorageKey() {
        const { value } = this.modal
        const elements = [this.$store.state.documentUrl, value.identifier, value.parent_id, value.type, value.id, 'unsavedValue']
        return join(compact(elements), '/')
      },
      setValue() {
        return new Promise((resolve) => {
          if (this.unsavedValue) {
            this.$store.commit('confirmationModal/show', {
              iconClass: 'file alternate',
              header: "Would you like to restore your unsaved document?",
              prompt: "If you later decide you don't want the restored version you can click Cancel and answer No to this question next time to load the saved version.",
              positiveAction: () => {
                resolve(this.unsavedValue)
              },
              negativeAction: () => {
                resolve(this.savedValue)
              }
            })
          } else {
            resolve(this.savedValue)
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ui.modal {
    margin: 0 auto !important;

    > .title {
      padding: 1.25rem 1.5rem;
    }

    > .content {
      height: calc(88vh - 10em);
      overflow: hidden;
      padding: 0 !important;
    }

    > .header {
      float: left;
    }

    > .actions {
      float: right;
    }
  }
</style>

<style lang="scss">
  .fr-box {
    height: 100%;
    overflow: hidden;
    line-height: initial;

    .fr-wrapper {
      height: 100%;
      overflow: auto;
    }
  }
</style>
