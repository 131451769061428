export default class ValueMover {
  constructor(component) {
    this.document_url = component.$store.state.documentUrl
  }

  moveValue(value, index) {
    $.ajax({
      url: `${this.document_url}/values/${value.id}/move_to`,
      method: 'PATCH',
      data: {
        position: index
      }
    })
  }
}
