import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).popup({ on: 'click' })
  }

  disconnect() {
    $(this.element).popup('destroy')
  }
}
