<template>
  <a @click.stop='view'>{{ title || 'Untitled document' }}</a>
</template>

<script>
  export default {
    props: {
      title: String,
      escaped_title: String,
      content: String
    },
    methods: {
      view() {
        this.$store.commit('documentViewer/show', {
          content: this.content,
          title: this.escaped_title
        }
        )
      }
    }
  }
</script>

<style scoped>
  a {
    cursor: pointer;
  }
</style>
