import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["menu", "section", "subsection"]

  attach() {
    const controller = this

    $(this.sectionTargets).visibility({
      once: false,
      offset: 90,
      observeChanges: false,
      onTopPassed() {
        controller.activateSection(this)
      },
      onTopPassedReverse() {
        controller.activatePreviousSection()
      }
    })

    $(this.subsectionTargets).visibility({
      once: false,
      offset: 90,
      observeChanges: false,
      onTopPassed() {
        controller.activateSubsection(this)
      },
      onBottomPassedReverse() {
        controller.activateSubsection(this)
      }
    })
  }

  refresh() {
    $(this.sectionTargets).visibility('refresh')
    $(this.subsectionTargets).visibility('refresh')
  }

  scrollToXlink(event) {
    event.preventDefault()

    const href = $(event.currentTarget).attr('xlink:href')

    $('html, body').animate({
      scrollTop: $(href).offset().top - 80
    }, 500)
  }

  activateSection(section) {
    const $section = $(section)
    const index = $(this.sectionTargets).index($section)
    const $shortcutSections = $(this.menuTarget).children('.item')
    const $activeSection = $shortcutSections.eq(index)
    const isActive = $activeSection.hasClass('active')

    if(!isActive) {
      $shortcutSections.filter('.active').find('.menu > .item').removeClass('active')
      $shortcutSections.filter('.active').removeClass('active')
      $activeSection.addClass('active')
    }
  }

  activatePreviousSection() {
    const $shortcutSections = $(this.menuTarget).children('.item')
    const $section = $shortcutSections.filter('.active')

    if($section.prev().length > 0) {
      $section.find('.menu > .item').removeClass('active')
      $section.removeClass('active').prev('.item').addClass('active')
    }
  }

  activateSubsection(subsection) {
    const $subsection = $(subsection)
    const index = $(this.subsectionTargets).index($subsection)
    const $shortcutSubsections = $(this.menuTarget).find('.menu > .item')
    const $activeSubsection = $shortcutSubsections.eq(index)
    const isActive = $activeSubsection.hasClass('active')

    if(index != -1 && !isActive) {
      $shortcutSubsections.filter('.active').removeClass('active')
      $activeSubsection.addClass('active')
    }
  }
}
