const defaultState = () => ({
  isVisible: false,
  content: null,
  title: null,
  referenceId: null
})

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    show(state, payload) {
      Object.assign(state, payload)
      state.isVisible = true
    },
    hide(state) {
      Object.assign(state, defaultState())
    }
  }
}
