import FileField from '../fields/file_field.vue';
import BrowseField from '../fields/browse_field.vue';
import DriveField from '../fields/drive_field.vue';
import MixedValue from '../fields/mixed_value.vue';
import TextValue from '../fields/text_value.vue';

export default {
  props: {
    content: String
  },
  render(createElement) {
    return createElement({
      template: this.content || '<div></div>',
      components: {
        FileField,
        BrowseField,
        DriveField,
        MixedValue,
        TextValue
      }
    })
  }
}
