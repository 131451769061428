import Trix from "trix"
import 'trix/dist/trix.css'
import './content.css'

Trix.config.attachments.preview.caption.name = true
Trix.config.attachments.preview.caption.size = false

Trix.config.textAttributes.highlight = {
  tagName: "mark", inheritable: true
}

document.addEventListener("trix-initialize", (event) => {
  const strikeButton = event.target.toolbarElement.querySelector("[data-trix-attribute=strike]")
  const highlightButtonTemplate = '<button type="button" class="trix-button trix-button--icon trix-button--icon-highlight" data-trix-attribute="highlight" title="Highlight" tabindex="-1">Highlight</button>'

  strikeButton.insertAdjacentHTML("afterend", highlightButtonTemplate)
})
