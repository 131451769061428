<template>
	<draggable @change='move' v-bind='draggableOptions' v-model='values'>
		<component :is='valueComponent' :key='value.id' :short-identifier='shortIdentifier' :value='value' class='value' v-for='value in values'></component>
		<add-button :short-identifier='shortIdentifier' :types='types' slot='footer' v-on:open-add-modal='open_add_modal'></add-button>
	</draggable>
</template>

<script>
  import ValuesList from './mixins/values_list'

  export default {
    mixins: [ValuesList]
	}
</script>
