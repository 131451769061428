<template>
  <table class='ui small celled striped two column table'>
    <thead>
      <tr>
        <th :data-title='shortIdentifier'>{{ title || shortIdentifier }}</th>
        <th :data-title='child.identifier'>{{ child.title }}</th>
      </tr>
    </thead>
    <draggable v-model='values' @change='move' v-bind='draggableOptions' tag='tbody'>
      <two-column-table v-for='value in values' :value='value' :key='value.id' :short-identifier='shortIdentifier' :child='child' class='value'></two-column-table>
      <tr slot='footer' :class='{ add: hasValues }'>
        <td>
          <add-button :short-identifier='shortIdentifier' :types='types' v-on:open-add-modal='open_add_modal'></add-button>
        </td>
        <td></td>
      </tr>
    </draggable>
  </table>
</template>

<script>
  import ValuesList from './mixins/values_list'
  import TwoColumnTable from './two_column_table.vue'

  export default {
    props: {
      title: String,
      child: {
        type: Object,
        default() {
          return {
            title: 'Evidence',
            identifier: 'Evidence',
            types: 'Text,Image,Link,File,Drive,Mixed'
          }
        }
      }
    },
    mixins: [ValuesList],
    components: { TwoColumnTable }
  }
</script>
